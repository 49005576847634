import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import WithAuth from './common/WithAuth';
import CreateTenantPage from './page/CreateTenantPage';
import ErrorPage from './page/ErrorPage';
import IndexPage from './page/IndexPage';
import LoginPage from './page/LoginPage';
import TenantsPage from './page/TenantsPage';

const router = createBrowserRouter([
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '/tenants',
    element: (
      <WithAuth>
        <TenantsPage />
      </WithAuth>
    ),
  },
  {
    path: '/tenants/new',
    element: (
      <WithAuth>
        <CreateTenantPage />
      </WithAuth>
    ),
  },
  {
    path: '/',
    element: <IndexPage />,
    errorElement: <ErrorPage />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
