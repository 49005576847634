export interface Tenant {
  id: string;
  name: string;
  version: string;
  status: string;
  status_detail: string;
  message?: string;
  created_at: string;
}

export class ApiClient {
  constructor(protected baseUrl: string) {}

  protected getToken(): string {
    return localStorage.getItem('token') || '';
  }

  protected getHeaders(): HeadersInit {
    return {
      'content-type': 'application/json',
      authorization: `Bearer ${this.getToken()}`,
    };
  }

  async login(userId: string, password: string): Promise<string> {
    const res = await fetch(`${this.baseUrl}/api/v1/login`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        user_id: userId,
        password,
      }),
    });
    return (await res.json()).token;
  }

  async getTenants() {
    const res = await fetch(`${this.baseUrl}/api/v1/tenants`, {
      headers: this.getHeaders(),
    });
    return (await res.json()).tenants;
  }

  async createTenant(
    tenantId: string,
    tenantName: string
  ): Promise<{ tenant_id: string; initial_admin_password: string }> {
    const res = await fetch(`${this.baseUrl}/api/v1/tenant_requests`, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify({
        operation: 'create',
        params: {
          tenant_id: tenantId,
          tenant_name: tenantName,
        },
      }),
    });

    if (res.status >= 400) {
      throw new Error('api error');
    }

    return await res.json();
  }

  async deleteTenant(tenantId: string): Promise<void> {
    const res = await fetch(`${this.baseUrl}/api/v1/tenant_requests`, {
      method: 'POST',
      headers: this.getHeaders(),
      body: JSON.stringify({
        operation: 'delete',
        params: {
          tenant_id: tenantId,
        },
      }),
    });
    await res.json();
  }
}

export const apiClient = new ApiClient(process.env.REACT_APP_API_BASEURL as string);
