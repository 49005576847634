import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { apiClient } from '../api/ApiClient';

type State = 'INPUT' | 'CREATING' | 'DONE';

function isAlnum(str: string): boolean {
  const regexp = /^[a-zA-Z0-9]+$/i;
  return regexp.test(str);
}

export default function CreateTenantPage() {
  const [state, setState] = useState<State>('INPUT');
  const [tenantId, setTenantId] = useState<string>('');
  const [tenantName, setTenantName] = useState<string>('');
  const [initialPassword, setInitialPassword] = useState<string>('');
  const [validationError, setValidationError] = useState<string>('');

  const onSubmitClicked = async () => {
    if (tenantId.length === 0 || tenantName.length === 0) {
      setValidationError('テナントIDとテナント名を指定してください');
      return;
    }

    if (!isAlnum(tenantId) || tenantId.length > 20) {
      setValidationError('テナントIDは英数字で２０文字以内にしてください');
      return;
    }

    setState('CREATING');
    try {
      const res = await apiClient.createTenant(tenantId, tenantName);
      setInitialPassword(res.initial_admin_password);
    } catch (err) {
      setValidationError('テナントの作成に失敗しました');
      // 裏にstateが残るのでresetしておく
      setTenantId('');
      setTenantName('');
      setState('INPUT');
      return;
    }
    setState('DONE');
  };
  const onTenantIdChanged = (e: any) => setTenantId(e.target.value);
  const onTenantNameChanged = (e: any) => setTenantName(e.target.value);

  return (
    <Box sx={{ minWidth: '800px', maxWidth: '1200px', m: 3 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        S Cloud管理画面 / テナント作成
      </Typography>

      {state === 'CREATING' && <CircularProgress />}

      {state === 'DONE' && (
        <Box sx={{ minWidth: '400px', maxWidth: '700px' }}>
          <p>テナントを作成中です。</p>
          <b>管理ユーザ初期パスワードは後から確認できないため、必ずメモしてください。</b>
          <p>一覧画面に戻り、ステータスが「有効」になるまでお待ち下さい。</p>

          <Box sx={{ m: 2 }}>
            <p>テナントID: {tenantId}</p>
            <p>管理ユーザ名: admin</p>
            <p>管理ユーザ初期パスワード: {initialPassword}</p>
          </Box>

          <Box>
            <Link to={'/tenants'}>戻る</Link>
          </Box>
        </Box>
      )}

      {state === 'INPUT' && (
        <Box sx={{ minWidth: '400px', maxWidth: '400px' }}>
          {validationError && <Box>{validationError}</Box>}
          <TextField label="テナントID" fullWidth sx={{ mb: 2 }} onChange={onTenantIdChanged} />
          <TextField label="テナント名" fullWidth sx={{ mb: 2 }} onChange={onTenantNameChanged} />
          <Button fullWidth onClick={onSubmitClicked} type="button">
            テナント作成
          </Button>

          <Box>
            <Link to={'/tenants'}>戻る</Link>
          </Box>
        </Box>
      )}
    </Box>
  );
}
