import { Alert, Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid, TextField } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiClient } from '../api/ApiClient';
import './LoginPage.css';

function LoginPage() {
  const [userId, setUserId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>();
  const navigate = useNavigate();

  const onUserIdChange = (e: any) => {
    setUserId(e.target.value);
  };
  const onPasswordChange = (e: any) => {
    setPassword(e.target.value);
  };

  const onLoginClicked = async () => {
    setLoading(true);
    try {
      const token = await apiClient.login(userId, password);
      localStorage.setItem('token', token);
      navigate('/tenants');
    } catch (err) {
      console.error(err);
      setErrorMsg('ログインに失敗しました。ユーザIDとパスワードを確認してください');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
        <Grid item>
          <Card sx={{ minWidth: 400 }}>
            <CardHeader title="S-Cloud 管理画面"></CardHeader>
            <CardContent>
              {loading && (
                <Box justifyContent="center" sx={{ display: 'flex' }}>
                  <CircularProgress />
                </Box>
              )}
              {!loading && (
                <Box>
                  {errorMsg && (
                    <Alert severity="error" sx={{ mb: 1 }}>
                      {errorMsg}
                    </Alert>
                  )}
                  <TextField
                    required
                    id="user_id"
                    label="ユーザID"
                    variant="outlined"
                    fullWidth
                    sx={{ mb: 1 }}
                    onChange={onUserIdChange}
                  />
                  <TextField
                    required
                    id="password"
                    label="パスワード"
                    variant="outlined"
                    type="password"
                    fullWidth
                    sx={{ mb: 1 }}
                    onChange={onPasswordChange}
                  />
                  <Button variant="text" fullWidth type="submit" onClick={onLoginClicked}>
                    ログイン
                  </Button>
                </Box>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default LoginPage;
