import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiClient, Tenant } from '../api/ApiClient';

function getUrls(tenantId: string): string[] {
  const urls = [];

  urls.push(`https://${tenantId}.s-cloud.app`);
  urls.push(`https://${tenantId}.smart-st.app`);
  urls.push(`https://org-kamasu-eve-saas-${tenantId}.web.app`);

  return urls;
}

export default function TenantsPage() {
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [showDeletingDialog, setShowDeletingDialog] = useState<boolean>(false);
  const [deletingTenant, setDeletingTenant] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const refreshTenants = async () => {
    const tenants = await apiClient.getTenants();
    const activeTenants = tenants.filter((t: any) => t.status !== 'deleted');
    setTenants(activeTenants);
  };

  useEffect(() => {
    refreshTenants();
  }, []);

  const onRefreshTenantsClicked = () => {
    refreshTenants();
  };

  const onCreateTenantClicked = () => {
    navigate('/tenants/new');
  };

  const showStatus = (status: string): string => {
    switch (status) {
      case 'creating':
        return '作成中';
      case 'enabled':
        return '有効';
      case 'error':
        return 'エラー';
      default:
        return '不明';
    }
  };

  const showDeleteDialog = (tenant: any) => {
    setDeletingTenant(tenant);
    setShowDeletingDialog(true);
  };

  const cancelDelete = () => {
    setShowDeletingDialog(false);
    setDeletingTenant(null);
  };

  const execDelete = async () => {
    setLoading(true);
    await apiClient.deleteTenant(deletingTenant.id);
    setLoading(false);
    setShowDeletingDialog(false);
    setDeletingTenant(null);
    await refreshTenants();
  };

  return (
    <Box sx={{ minWidth: '800px', maxWidth: '1200px', m: 3 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        S Cloud管理画面 / テナント一覧
      </Typography>

      <Backdrop
        open={loading}
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.tooltip + 1,
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog open={showDeletingDialog}>
        <DialogTitle>テナント削除</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div>
              テナント {deletingTenant?.name}（テナントID：{deletingTenant?.id}）を削除します。よろしいですか？
            </div>
            <div>
              <b>注意：この操作は巻き戻せません</b>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={execDelete} color="error">
            削除する
          </Button>
          <Button onClick={cancelDelete}>キャンセル</Button>
        </DialogActions>
      </Dialog>

      <Box>
        <Button onClick={onRefreshTenantsClicked} sx={{ mr: 2 }}>
          一覧を更新する
        </Button>
        <Button disabled={tenants.some((t) => t.status === 'creating')} onClick={onCreateTenantClicked}>
          テナントを作成する
        </Button>
      </Box>

      {tenants.some((t) => t.status === 'creating') && (
        <Box>テナント作成中は、追加でテナントの作成ができません。完了までお待ち下さい。</Box>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>テナントID</TableCell>
              <TableCell>ステータス</TableCell>
              <TableCell>テナント名</TableCell>
              <TableCell>URL</TableCell>
              <TableCell>操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tenants.map((t) => (
              <TableRow key={t.id}>
                <TableCell>{t.id}</TableCell>
                <TableCell>{showStatus(t.status)}</TableCell>
                <TableCell>{t.name}</TableCell>
                <TableCell>
                  {getUrls(t.id).map((url) => (
                    <div key={url}>
                      <Link href={url} target="_blank" rel="noreferrer" underline="none">
                        {url}
                      </Link>
                    </div>
                  ))}
                </TableCell>
                <TableCell>
                  <Button disabled={t.status !== 'enabled'} color="error" onClick={() => showDeleteDialog(t)}>
                    削除
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
